import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, Select, MenuItem, Button } from '@mui/material';
import AdvancedFilterBox from './AdvancedFilterBox';
import { SelectChangeEvent } from '@mui/material/Select';

interface Filter {
  id: number;
  data: any; // The filter data selected from categories
  not: boolean;
}

interface AdvancedFiltersProps {
  filters: Filter[];
  setFilters: React.Dispatch<React.SetStateAction<Filter[]>>;
  onAddFilter: () => void;
}

const AdvancedFilters: React.FC<AdvancedFiltersProps> = ({ filters, setFilters, onAddFilter }) => {
  const [logicalOperator, setLogicalOperator] = useState<'AND' | 'OR'>('AND');
  const [isAddingFilter, setIsAddingFilter] = useState<boolean>(false);
  const prevFiltersLength = useRef<number>(filters.length);

  // Handle logical operator change
  const handleLogicalOperatorChange = (event: SelectChangeEvent<'AND' | 'OR'>) => {
    setLogicalOperator(event.target.value as 'AND' | 'OR');
  };

  // Remove a filter
  const removeFilter = (id: number) => {
    setFilters(filters.filter((filter) => filter.id !== id));
  };

  // Update a filter (toggle NOT)
  const updateFilter = (updatedFilter: Filter) => {
    setFilters(filters.map((filter) => (filter.id === updatedFilter.id ? updatedFilter : filter)));
  };

  // Effect to detect when a filter has been added
  useEffect(() => {
    if (isAddingFilter && filters.length > prevFiltersLength.current) {
      // Filter has been added
      setIsAddingFilter(false);
    }
    // Update the previous filters length
    prevFiltersLength.current = filters.length;
  }, [filters, isAddingFilter]);

  // Handle adding a new filter
  const handleAddFilterClick = () => {
    setIsAddingFilter(true);
    onAddFilter();
  };

  return (
    <Box sx={{ padding: 2, backgroundColor: '#fff', height: '100%', overflowY: 'auto' }}>
      <Typography variant="h5" sx={{ fontFamily: 'Inter', fontWeight: 700, marginBottom: '16px' }}>
        Advanced Filters
      </Typography>

      {/* Logical Operator Selection */}
      <Box sx={{ marginBottom: '16px' }}>
        <Typography
          variant="subtitle1"
          sx={{ fontFamily: 'Inter', fontWeight: 600, color: '#004F6E', marginBottom: '8px' }}
        >
          Logical Operator Between Filters:
        </Typography>
        <Select
          value={logicalOperator}
          onChange={handleLogicalOperatorChange}
          sx={{ fontFamily: 'Inter', fontSize: '1rem', width: '100px', height: '40px' }}
        >
          <MenuItem value="AND">AND</MenuItem>
          <MenuItem value="OR">OR</MenuItem>
        </Select>
      </Box>

      {/* List of Filters */}
      {filters.map((filter, index) => (
        <Box key={filter.id} sx={{ marginBottom: '16px' }}>
          {/* Logical operator between filters */}
          {index > 0 && (
            <Typography
              sx={{
                fontFamily: 'Inter',
                fontWeight: 600,
                color: '#004F6E',
                textAlign: 'center',
                marginBottom: '8px',
              }}
            >
              {logicalOperator}
            </Typography>
          )}

          {/* Filter box */}
          <AdvancedFilterBox
            filter={filter}
            onUpdate={updateFilter}
            onRemove={() => removeFilter(filter.id)}
          />
        </Box>
      ))}

      {/* Display "Adding filter..." message */}
      {isAddingFilter && (
        <Typography
          variant="body1"
          sx={{
            fontFamily: 'Inter',
            fontWeight: 500,
            color: '#004F6E',
            marginTop: '16px',
          }}
        >
          Adding filter...
        </Typography>
      )}

      {/* Add Filter button */}
      <Button
        variant="contained"
        onClick={handleAddFilterClick}
        disabled={isAddingFilter}
        sx={{
          fontFamily: 'Inter',
          fontWeight: 600,
          color: '#fff',
          backgroundColor: isAddingFilter ? '#A9A9A9' : '#004F6E',
          textTransform: 'none',
          marginTop: '16px',
        }}
      >
        + Add Filter
      </Button>
    </Box>
  );
};

export default AdvancedFilters;
