import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  IconButton,
  MenuItem,
  Typography,
  Box,
  ListItemIcon,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import checkCircleIcon from "../icons/check_circle.svg";
import { useAuth } from "../../contexts/AuthContext";
import { Organization } from "../../types/organization";

interface ChangeOrganizationDialogProps {
  open: boolean;
  onClose: () => void;
}

const ChangeOrganizationDialog: React.FC<ChangeOrganizationDialogProps> = ({ open, onClose }) => {
  const { userOrgs, organization, changeOrganization } = useAuth();
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleOrganizationSelect = (org: Organization) => {
    changeOrganization(org.id); // Use changeOrganization to update state and localStorage
    onClose(); // Close the dialog after selecting an organization
  };

  // Filter organizations based on search term
  const filteredOrgs = userOrgs.filter((org) =>
    org.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
      PaperProps={{
        sx: { borderRadius: "10px" },
      }}
    >
      {/* Dialog Title Section */}
      <Box
        sx={{
          backgroundColor: "#DEF2FF",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "16px",
          borderTopLeftRadius: "16px",
          borderTopRightRadius: "16px",
        }}
      >
        <Typography variant="h6" sx={{ fontFamily: "Inter", fontWeight: "bold" }}>
          Change Organization
        </Typography>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </Box>

      <DialogContent sx={{ height: "400px" }}>
        {/* Search Bar */}
        <Box display="flex" justifyContent="center" mb={2}>
          <input
            type="text"
            placeholder="Search Organization"
            value={searchTerm}
            onChange={handleSearchChange}
            style={{
              width: "100%",
              padding: "8px",
              borderRadius: "5px",
              border: "1px solid #6C8EA5",
              background: "rgba(108, 142, 165, 0.08)",
              fontFamily: "Inter",
            }}
          />
        </Box>

        {/* Organization List */}
        <Box maxHeight="300px" overflow="auto">
          {filteredOrgs.map((org) => (
            <MenuItem
              key={org.id}
              onClick={() => handleOrganizationSelect(org)}
              selected={organization?.id === org.id}
              disableRipple
              sx={{ justifyContent: "space-between", borderRadius: "5px" }}
            >
              <Box display="flex" flexDirection="column" alignItems="flex-start">
                <Typography>{org.name}</Typography>
                {org.logo && (
                  <img
                    alt={org.name}
                    src={`data:image/png;base64,${org.logo}`}
                    style={{ height: "15px", width: "auto", marginTop: 4 }}
                  />
                )}
              </Box>
              {organization?.id === org.id && (
                <ListItemIcon sx={{ minWidth: 0, marginLeft: "auto" }}>
                  <img
                    src={checkCircleIcon}
                    alt="Selected"
                    style={{ height: "24px", width: "24px" }}
                  />
                </ListItemIcon>
              )}
            </MenuItem>
          ))}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ChangeOrganizationDialog;