import React, { useState } from "react";
import { Menu, MenuItem, Box, Typography, ListItemIcon, Divider, Button } from "@mui/material";
import accountCircleIcon from "../icons/account_circle.svg";
import checkCircleIcon from "../icons/check_circle.svg";
import ChangeOrganizationDialog from "./ChangeOrganizationDialog";
import { useAuth } from "../../contexts/AuthContext";
import { Organization } from "../../types/organization";

const ProfileDropdown: React.FC = () => {
  const { userOrgs, organization, changeOrganization, logout, setActivePage } = useAuth();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOrganizationSelect = (org: Organization) => {
    changeOrganization(org.id); // Update the selected organization in AuthContext
    handleMenuClose();
  };  

  return (
    <Box display="flex" alignItems="center">
      <Box
        display="flex"
        alignItems="center"
        bgcolor="white"
        borderRadius="8px"
        p={1}
        sx={{ cursor: "pointer" }}
        onClick={handleMenuOpen}
      >
        <img
          src={accountCircleIcon}
          alt="Profile"
          style={{ height: "25px", width: "25px" }}
        />
        {organization?.logo && (
          <img
            alt={organization.name}
            src={`data:image/png;base64,${organization.logo}`}
            style={{ height: "20px", width: "auto", marginLeft: 15 }}
          />
        )}
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        MenuListProps={{
          sx: {
            width: 275,
            "& .MuiMenuItem-root": {
              border: "1px solid transparent",
              marginX: "8px",
              "&:hover": {
                borderColor: "#C8D7E1",
                backgroundColor: "rgba(197, 225, 243, 0.27)",
                borderRadius: "5px",
              },
              padding: "8px 16px",
            },
          },
        }}
      >
        {userOrgs.length > 5 ? (
          <Button
            onClick={() => setDialogOpen(true)}
            sx={{
              borderRadius: "5px",
              border: "1px solid #6C8EA5",
              background: "rgba(197, 225, 243, 0.46)",
              color: "#33475B",
              fontFamily: "Inter",
              padding: "8px 16px",
              width: "100%",
              textAlign: "center",
            }}
          >
            Change Organization
          </Button>
        ) : (
          userOrgs.map((org) => (
            <MenuItem
              key={org.id}
              onClick={() => handleOrganizationSelect(org)}
              selected={organization?.id === org.id}
              sx={{ justifyContent: "space-between", borderRadius: "5px" }}
            >
              <Box display="flex" flexDirection="column" alignItems="flex-start">
                <Typography>{org.name}</Typography>
                {org.logo && (
                  <img
                    alt={org.name}
                    src={`data:image/png;base64,${org.logo}`}
                    style={{ height: "15px", width: "auto", marginTop: 4 }}
                  />
                )}
              </Box>
              {organization?.id === org.id && (
                <ListItemIcon sx={{ minWidth: 0, marginLeft: 4, borderRadius: "5px" }}>
                  <img
                    src={checkCircleIcon}
                    alt="Selected"
                    style={{ height: "20px", width: "20px" }}
                  />
                </ListItemIcon>
              )}
            </MenuItem>
          ))
        )}

        <Divider sx={{ my: 1, backgroundColor: "#e0e0e0" }} />
        <MenuItem onClick={() => { setActivePage("users"); handleMenuClose(); }}>My Profile</MenuItem>
        <MenuItem onClick={() => { setActivePage("organization"); handleMenuClose(); }}>Organization Profile</MenuItem>
        <MenuItem onClick={() => { setActivePage("subscriptions"); handleMenuClose(); }}>Manage Subscriptions</MenuItem>
        <Divider sx={{ my: 1, backgroundColor: "#e0e0e0" }} />
        <MenuItem onClick={() => { logout(); handleMenuClose(); }}>Log Out</MenuItem>
      </Menu>

      <ChangeOrganizationDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
    </Box>
  );
};

export default ProfileDropdown;