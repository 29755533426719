import React, { useState } from 'react';
import { Box, Paper, InputBase, Typography, Checkbox, List, ListItem, ListItemText } from '@mui/material';

interface SavedListsProps {
  lists: { id: number; name: string }[];
  selectedLists: Set<number>;
  setSelectedLists: React.Dispatch<React.SetStateAction<Set<number>>>;
}

const SavedLists: React.FC<SavedListsProps> = ({ lists, selectedLists, setSelectedLists }) => {
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const filteredLists = lists.filter((list) =>
    list.name.toLowerCase().includes(searchQuery)
  );

  const handleToggleSelect = (listId: number) => {
    setSelectedLists((prevSelected) => {
      const newSelected = new Set(prevSelected);
      newSelected.has(listId) ? newSelected.delete(listId) : newSelected.add(listId);
      return newSelected;
    });
  };

  return (
    <Box sx={{ padding: 2 }}>
      {/* Search bar */}
      <Paper
        component="form"
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: 350,
          height: 35,
          borderRadius: '5px',
          border: '1px solid #6C8EA5',
          background: 'rgba(108, 142, 165, 0.08)',
          boxShadow: 'none',
          padding: '2px 4px',
          mb: 2,
        }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search lists..."
          inputProps={{ 'aria-label': 'search for list' }}
          value={searchQuery}
          onChange={handleSearchChange}
        />
      </Paper>

      {/* Title */}
      <Typography
        variant="h6"
        sx={{
          fontFamily: 'Inter',
          fontWeight: 700,
          color: '#004F6E',
          marginLeft: 1,
        }}
      >
        Select List(s)
      </Typography>

      {/* List of checkable lists */}
      <List>
        {filteredLists.map((list) => (
          <ListItem
            key={list.id}
            button
            disablePadding
            disableRipple
            onClick={() => handleToggleSelect(list.id)}
          >
            <Checkbox
              checked={selectedLists.has(list.id)}
              onClick={(e) => e.stopPropagation()} // Prevent list item click
              onChange={() => handleToggleSelect(list.id)}
              color="primary"
            />
            <ListItemText primary={list.name} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default SavedLists;
