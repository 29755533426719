import { apiClient } from './apiClient';
import { 
    PreSignedUrlResponse,
    Mappings,
    SupporterImportResponse,
    TaskStatusResponse,
    ImportResultResponse,
    SupporterListDataResponse,
    TempSupporterResponse } from '../types/supporters';

// Retrieve all lists for a specific user based on their user ID
export const getUserLists = async (userId: number): Promise<SupporterListDataResponse> => {
    const response = await fetch(`/api/user-lists/${userId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error(`Error fetching user lists: ${response.statusText}`);
    }

    return response.json();
};


export const createSupporterList = async (listName: string, supporterIds: number[]): Promise<{ listId: number }> => {
    const response = await fetch('/api/supporters-to-list', {
        method: 'POST',
        body: JSON.stringify({ listName, supporterIds }),
    });

    return response.json();
};

// updateSupporterList
export const updateSupporterList = async (listId: number, supporterIds: number[], remove: boolean = false): Promise<{ listId: number }> => {
    const response = await apiClient('/api/supporters-to-list', {
        method: 'PATCH',
        body: JSON.stringify({ listId, supporterIds, remove }),
    });

    return response.json();
}


// Fetch pre-signed URL for uploading a file to S3
export const getPresignedUrl = async (fileName: string, bucketName: string): Promise<PreSignedUrlResponse> => {
  const response = await fetch(`/api/supporter-list`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify({ fileName, bucketName }),
  });

  if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
  }

  return response.json();
};

// Upload the file to S3 using the pre-signed URL
export const uploadFileToS3 = async (file: File, preSignedUrl: string): Promise<void> => {
  const response = await fetch(preSignedUrl, {
      method: 'PUT',
      headers: {
          'Content-Type': file.type,
      },
      body: file,
  });

  if (!response.ok) {
      throw new Error(`Error uploading file: ${response.statusText}`);
  }
};

// The upload the file:
export const uploadFile = async (file: File): Promise<{ file_key: string; url: string }> => {
  const formData = new FormData();
  formData.append('file', file);
  const response = await apiClient(`/api/temp-supporters`, {
    method: 'POST',
    body: formData,
  });

  if (!response.ok) {
    throw new Error(`Error uploading file: ${response.statusText}`);
  }

  return response.json();
};


// Submit mappings and start the import process
export const submitMappings = async (fileKey: string, mappings: Mappings): Promise<SupporterImportResponse> => {
    const response = await apiClient(`/api/submit-mappings`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ fileKey, mappings }),
    });

    if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
    }

    return response.json();
};

// Check the status of the processing task
export const getTaskStatus = async (taskId: string): Promise<TaskStatusResponse> => {
    const response = await apiClient(`/api/task-status/${taskId}`);
    return response.json();
}

// Retrieve results of the import process after completion using a task identifier
export const getImportResults = async (taskId: string): Promise<ImportResultResponse> => {
    const response = await apiClient(`/api/import-results/${taskId}`);
    return response.json();
}

export const getTempSupporters = async (
    taskId: string,
    type?: string,
    page: number = 1,
    pageSize: number = 10
): Promise<TempSupporterResponse> => {
    // Build the query string
    const queryParams = new URLSearchParams({
        taskId,
        type: type || '', // Ensure type is optional
        page: page.toString(),
        pageSize: pageSize.toString(),
    });

    // Make the GET request with query string
    const response = await apiClient(`/api/temp-supporters?${queryParams.toString()}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    
    // Return the parsed JSON response
    return response.json();
}

// Create new supporter entries from selected temporary supporters
export const createSupporters = async (tempSupporterIds: number[]): Promise<SupporterImportResponse> => {
    const response = await apiClient(`/api/supporters`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ tempSupporterIds }),
    });
    return response.json();
}

// Update existing supporter records by overriding their data with new data received from temporary supporters
export const updateSupporters = async (updates: { supporterId: number; tempSupporterId: number }[]): Promise<SupporterImportResponse> => {
    const response = await apiClient(`/api/supporters`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ updates }),
    });
    return response.json();
}

// New service function to handle bulk action updates
export const updateSupportersWithAction = async (
    action: { [key: string]: any },
    supporterIds: number[]
  ): Promise<SupporterImportResponse> => {
    // Construct the request body for bulk action updates
    const requestBody = {
      updates: [
        {
          action,
          supporterIds,
        },
      ],
    };
  
    const response = await fetch(`/api/supporters`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });
  
    if (!response.ok) {
      throw new Error(`Failed to update supporters: ${response.statusText}`);
    }
  
    return response.json();
  };

// Create a new group from selected supporters and assign the supporters to this group
export const createSupporterGroup = async (supporterIds: number[], groupName: string): Promise<SupporterImportResponse> => {
    const response = await apiClient(`/api/supporters-group`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ supporterIds, groupName }),
    });
    return response.json();
}

// Finalize the transfer of specified temporary supporters to the main supporters table after all conflicts have been resolved
export const finalizeTempSupporters = async (tempSupporterIds: number[]): Promise<SupporterImportResponse> => {
    const response = await apiClient(`/api/temp-supporters`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ tempSupporterIds }),
    });
    return response.json();
}

// Export the processed data into a CSV file, allowing filtering by specific conflict types
export const exportConflicts = async (taskId: string, type: string): Promise<TempSupporterResponse> => {
    const response = await apiClient(`/api/temp-supporters/conflicts`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ taskId, type }),
    });
    return response.json();
}

export const getTempSupporterConflicts = async (
    taskId: string,
    conflictType?: string, // Made conflictType optional
    page: number = 1, // Added pagination parameters
    pageSize: number = 1000000000
): Promise<any> => {
    // Construct the base URL
    let url = `/api/tempsupporterconflicts/${taskId}?page=${page}&page_size=${pageSize}`;

    // Add conflictType to the URL if it's provided
    if (conflictType) {
        url += `&type=${conflictType}`;
    }

    // Make the API request
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error(`Error fetching conflicts: ${response.statusText}`);
    }

    return response.json();
};

// Fetch temp supporters based on a list of IDs
export const getTempSupportersByIds = async (idList: number[]): Promise<any> => {
    const response = await apiClient(`/api/temp-supporter-data`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ tempSupporterIds: idList }),
    });

    if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
    }

    return response.json();
};

// Fetch temp supporters based on a list of IDs
export const getSupportersByIds = async (idList: number[]): Promise<any> => {
    const response = await apiClient(`/api/supporter-data`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ supporterIds: idList }),
    });

    if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
    }

    return response.json();
};

// Delete TempSupporters based on task_id
export const deleteTempSupporters = async (taskId: string): Promise<{ message: string }> => {
    const response = await apiClient(`/api/temp-supporters/${taskId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
    }

    return response.json();
};

// Fetch all supporters based on organization ID
export const getAllSupportersByOrg = async (orgID: number): Promise<any> => {
    const response = await fetch(`/api/supporters/all/${orgID}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error(`Error fetching supporters: ${response.statusText}`);
    }

    return response.json();
};
