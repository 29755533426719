import React from 'react';
import { Grid } from '@mui/material';
import SupporterOption from '../../components/SupporterOption';
import { useAuth } from '../../contexts/AuthContext';

const Supporters: React.FC = () => {
  const { activePage, setActivePage } = useAuth();
  return (
    <Grid container spacing={2} style={{ padding: '20px' }}>
      {/* Pass setActivePage to each SupporterOption */}
      <SupporterOption title="Import Supporters" page="import-supporters" setActivePage={setActivePage} />
      <SupporterOption title="Import History" page="import-history" setActivePage={setActivePage} />
      <SupporterOption title="View Supporters" page="view-supporters" setActivePage={setActivePage} />
    </Grid>
  );
};

export default Supporters;
