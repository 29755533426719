import React from 'react';
import Banner from '../../Banner';

const Header: React.FC = () => {
  return (
    <div>
      <Banner />
    </div>
  );
};

export default Header;