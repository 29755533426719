import React from 'react';
import Header from '../../components/header';
import Footer from '../../components/footer';
import { Toaster } from 'react-hot-toast';
import { CssBaseline, Grid } from '@mui/material';

interface LayoutProps {
  children?: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <>
      <CssBaseline />
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        sx={{ height: '100%', minHeight: '90vh' }}
      >
        <Grid item>
          <Header />
        </Grid>
        <Grid item style={{ flex: 1, padding: '64px 5px' }}>
          {children}
        </Grid>
        <Grid item>
          <Footer />
        </Grid>
      </Grid>
      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
};

export default Layout;