import React from 'react';
import { Box, Typography, IconButton, Checkbox, FormControlLabel } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface Filter {
  id: number;
  data: any; // The filter data selected from categories
  not: boolean;
}

interface AdvancedFilterBoxProps {
  filter: Filter;
  onUpdate: (filter: Filter) => void;
  onRemove: () => void;
}

const AdvancedFilterBox: React.FC<AdvancedFilterBoxProps> = ({ filter, onUpdate, onRemove }) => {
  const handleNotToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    onUpdate({ ...filter, not: event.target.checked });
  };

  return (
    <Box
      sx={{
        borderRadius: '5px',
        background: 'rgba(197, 225, 243, 0.27)',
        position: 'relative',
        marginBottom: '8px',
        overflow: 'hidden',
        border: '1px solid #004F6E',
        padding: '16px',
      }}
    >
      {/* Header with filter description and close button */}
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography
          variant="subtitle2"
          sx={{ fontFamily: 'Inter', fontWeight: 600, color: '#004F6E', fontSize: '1rem' }}
        >
          {filterDescription(filter)}
        </Typography>
        <IconButton
          onClick={onRemove}
          sx={{ padding: 0, marginLeft: 'auto', color: '#004F6E', fontSize: '1rem' }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>

      {/* NOT Toggle */}
      <FormControlLabel
        control={
          <Checkbox
            checked={filter.not}
            onChange={handleNotToggle}
            color="primary"
            sx={{ padding: 0, marginRight: '8px' }}
          />
        }
        label="NOT"
        sx={{ marginTop: '16px', color: '#004F6E', fontFamily: 'Inter', fontWeight: 600 }}
      />
    </Box>
  );
};

// Function to generate a description of the filter
function filterDescription(filter: Filter): string {
  // Implement this function to generate a user-friendly description of the filter
  // For example, return "Age < 50" or "Location = VA"
  return filter.data.description || 'Filter';
}

export default AdvancedFilterBox;
