import { toast } from 'react-hot-toast';
import { UNAUTHORIZED } from './constants';

export const apiClient = async (url: string, options: RequestInit = {}): Promise<Response> => {
    const token = sessionStorage.getItem('jwtToken');
    
    // We don't want to set the Content-Type header manually for FormData, as the browser does it automatically.
    const headers: Record<string, string> = {
        ...(token ? { 'Authorization': `Bearer ${token}` } : {}),
    };

    // If FormData is being used, we don't want to specify Content-Type.
    if (options.body instanceof FormData) {
        delete headers['Content-Type'];
    } else {
        headers['Content-Type'] = 'application/json';
    }

    const updatedOptions: RequestInit = {
        ...options,
        headers: {
            ...headers,
            ...options.headers,
        },
    };

    try {
        const response = await fetch(url, updatedOptions);

        if (!response.ok) {
            const errorMessage = await response.text();
            const message = errorMessage || `Error: ${response.statusText}`;
            throw new Error(message);
        }

        return response;
    } catch (error: any) {
        // Handle network errors or unexpected errors
        const errorDetail = JSON.parse(error.message).detail ? JSON.parse(error.message).detail : JSON.parse(error.message).message;
        toast.error(errorDetail || 'An unexpected error occurred. Please try again later.', {
            duration: 7000,
        });
        throw error;
    }
};

export const throwUnauthorized = () => {
    throw { name: UNAUTHORIZED, message: 'Unauthorized access' };
};

export const enforceLoggedIn = (req: Request, user: any) => {
    if (!user) throwUnauthorized();
    return user;
};