import React, { useState, useEffect } from 'react';
import {
  Box,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  CircularProgress,
  Divider,
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CloseIcon from '@mui/icons-material/Close';
import SavedLists from './SavedLists';
import SavedFilters from './SavedFilters';
import SupporterJourney from './SupporterJourney';
import SupporterTrends from './SupporterTrends';
import SupporterVoice from './SupporterVoice';
import AdvancedFilters from './AdvancedFilters';
import { getUserLists } from '../../api/supporterService';
import { useAuth } from '../../contexts/AuthContext';
import { SelectChangeEvent } from '@mui/material/Select';

interface FilterSidebarProps {
  isOpen: boolean;
  onClose: () => void;
  selectedFilterLists: Set<number>;
  setSelectedFilterLists: React.Dispatch<React.SetStateAction<Set<number>>>;
}

// Define the interface for SupporterJourney filters
interface SupporterJourneyFilters {
  outreachOptions: string[];
  preQualified: string | null;
  originalIdentity: string[];
}

// Define the interface for SupporterTrends filters
interface SupporterTrendsFilters {
  lybuntOptions: string[];
  legacyDonor: string | null;
  majorDonor: string | null;
  totalLifetimeGifts: number[];
}

interface SupporterVoiceFilters {
  missionAgreementOptions: string[];
  locationOptions: string[];
  optIn: string | null;
}

interface AdvancedFilter {
  id: number;
  data: any; // The filter data selected from categories
  not: boolean;
}

interface Filter {
  id: number;
  name: string;
}

const FilterSidebar: React.FC<FilterSidebarProps> = ({ isOpen, onClose, selectedFilterLists, setSelectedFilterLists }) => {
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [lists, setLists] = useState<any[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<Set<number>>(new Set());
  const [loading, setLoading] = useState(true);
  const [isAdvancedOpen, setIsAdvancedOpen] = useState(false);
  const { user } = useAuth();

  const [filters, setFilters] = useState<Filter[]>([
    { id: 1, name: 'Filter One' },
    { id: 2, name: 'Filter Two' },
  ]);

  const [supporterJourneyFilters, setSupporterJourneyFilters] = useState<SupporterJourneyFilters>({
    outreachOptions: [],
    preQualified: null,
    originalIdentity: [],
  });

  const [supporterTrendsFilters, setSupporterTrendsFilters] = useState<SupporterTrendsFilters>({
    lybuntOptions: [],
    legacyDonor: null,
    majorDonor: null,
    totalLifetimeGifts: [0, 1000000],
  });

  const [supporterVoiceFilters, setSupporterVoiceFilters] = useState<SupporterVoiceFilters>({
    missionAgreementOptions: [],
    locationOptions: [],
    optIn: null,
  });

  // Advanced Filters state
  const [advancedFilters, setAdvancedFilters] = useState<AdvancedFilter[]>([]);
  const [isAddingAdvancedFilter, setIsAddingAdvancedFilter] = useState(false);

  useEffect(() => {
    const fetchLists = async () => {
      if (user && lists.length === 0) {
        setLoading(true);
        try {
          const response = await getUserLists(user.id);
          setLists(response.results);
        } catch (error) {
          console.error('Error fetching lists:', error);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchLists();
  }, [user, lists]);

  const handleCategoryClick = (category: string) => {
    setSelectedCategory(category);
  };

  const toggleAdvancedFilters = () => {
    setIsAdvancedOpen(!isAdvancedOpen);
  };

  const handleAddAdvancedFilter = () => {
    setIsAddingAdvancedFilter(true);
    setSelectedCategory(null); // Reset to category selection
  };

  const handleAddFilterToAdvancedFilters = (filterData: any) => {
    const newFilter: AdvancedFilter = {
      id: Date.now(),
      data: filterData,
      not: false,
    };
    setAdvancedFilters([...advancedFilters, newFilter]);
    setIsAddingAdvancedFilter(false);
    setSelectedCategory(null); // Reset after adding
  };

  const renderCategoryComponent = () => {
    if (isAddingAdvancedFilter) {
      if (selectedCategory === null) {
        // Render category selection
        return (
          <List>
            {['Supporter Voice', 'Supporter Journey', 'Supporter Trends'].map((category) => (
              <ListItem
                key={category}
                button
                onClick={() => setSelectedCategory(category)}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '12px 16px',
                  fontFamily: 'Inter',
                  fontWeight: 700,
                  color: '#004F6E',
                }}
              >
                <ListItemText
                  primary={category}
                  primaryTypographyProps={{
                    sx: {
                      fontFamily: 'Inter',
                      fontWeight: 700,
                      color: '#004F6E',
                      fontSize: '1.1rem',
                    },
                  }}
                />
                <ListItemIcon
                  sx={{
                    minWidth: 'unset',
                    marginLeft: 'auto',
                    color: '#004F6E',
                  }}
                >
                  <ChevronRightIcon />
                </ListItemIcon>
              </ListItem>
            ))}
          </List>
        );
      } else {
        // Render the selected category component in filter selection mode
        switch (selectedCategory) {
          case 'Supporter Voice':
            return (
              <SupporterVoice
                onFilterSelect={handleAddFilterToAdvancedFilters}
                isAddingAdvancedFilter={true}
              />
            );
          case 'Supporter Journey':
            return (
              <SupporterJourney
                onFilterSelect={handleAddFilterToAdvancedFilters}
                isAddingAdvancedFilter={true}
              />
            );
          case 'Supporter Trends':
            return (
              <SupporterTrends
                onFilterSelect={handleAddFilterToAdvancedFilters}
                isAddingAdvancedFilter={true}
              />
            );
          default:
            return null;
        }
      }
    } else {
      switch (selectedCategory) {
        case 'Saved Filters':
          return (
            <SavedFilters
              filters={filters}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
            />
          );
        case 'Saved Lists':
          return (
            <SavedLists
              lists={lists}
              selectedLists={selectedFilterLists}
              setSelectedLists={setSelectedFilterLists}
            />
          );
        case 'Supporter Voice':
          return (
            <SupporterVoice
              filters={supporterVoiceFilters}
              setFilters={setSupporterVoiceFilters}
            />
          );
        case 'Supporter Journey':
          return (
            <SupporterJourney
              filters={supporterJourneyFilters}
              setFilters={setSupporterJourneyFilters}
            />
          );
        case 'Supporter Trends':
          return (
            <SupporterTrends
              filters={supporterTrendsFilters}
              setFilters={setSupporterTrendsFilters}
            />
          );
        default:
          return (
            <Typography variant="body1" sx={{ marginTop: 2 }}>
              Please select a category from the list.
            </Typography>
          );
      }
    }
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        right: 0,
        height: '100%',
        width: isAdvancedOpen ? '45vw' : '25vw', // Expands only when advanced filters are open
        bgcolor: 'white',
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        transition: 'width 0.3s ease',
        zIndex: 1200,
        transform: isOpen ? 'translateX(0)' : 'translateX(100%)',
      }}
    >
      <Box
        sx={{
          backgroundColor: '#DEF2FF',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '16px',
        }}
      >
        <Typography variant="h5" sx={{ fontFamily: 'Inter', fontWeight: 'bold', marginX: 2 }}>
          Filters
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      {/* Toggle Advanced Filters */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: '8px 16px',
          cursor: 'pointer',
          color: '#004F6E',
          fontWeight: 'bold',
          fontFamily: 'Inter',
        }}
        onClick={toggleAdvancedFilters}
      >
        <ChevronRightIcon
          sx={{
            transition: 'transform 0.3s',
            transform: isAdvancedOpen ? 'rotate(90deg)' : 'rotate(0deg)',
            marginRight: '8px',
          }}
        />
        Toggle Advanced Filters
      </Box>

      <Box sx={{ display: 'flex', height: '100%' }}>
        {isAdvancedOpen && (
          <AdvancedFilters
            filters={advancedFilters}
            setFilters={setAdvancedFilters}
            onAddFilter={handleAddAdvancedFilter}
          />
        )}

        {isAdvancedOpen && (
          <Divider orientation="vertical" flexItem sx={{ marginLeft: 2, marginRight: 2 }} />
        )}

        <Box
          sx={{
            width: '25vw', // Fixed width for categories, unaffected by advanced filter toggle
            padding: 2,
          }}
        >
          {selectedCategory === null ? (
            <List>
              {isAddingAdvancedFilter ? (
                ['Supporter Voice', 'Supporter Journey', 'Supporter Trends'].map((category) => (
                  <ListItem
                    key={category}
                    button
                    onClick={() => setSelectedCategory(category)}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '12px 16px',
                      fontFamily: 'Inter',
                      fontWeight: 700,
                      color: '#004F6E',
                    }}
                  >
                    <ListItemText
                      primary={category}
                      primaryTypographyProps={{
                        sx: {
                          fontFamily: 'Inter',
                          fontWeight: 700,
                          color: '#004F6E',
                          fontSize: '1.1rem',
                        },
                      }}
                    />
                    <ListItemIcon
                      sx={{
                        minWidth: 'unset',
                        marginLeft: 'auto',
                        color: '#004F6E',
                      }}
                    >
                      <ChevronRightIcon />
                    </ListItemIcon>
                  </ListItem>
                ))
              ) : (
                ['Saved Filters', 'Saved Lists', 'Supporter Voice', 'Supporter Journey', 'Supporter Trends'].map(
                  (category) => (
                    <ListItem
                      key={category}
                      button
                      onClick={() => handleCategoryClick(category)}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '12px 16px',
                        fontFamily: 'Inter',
                        fontWeight: 700,
                        color: '#004F6E',
                      }}
                    >
                      <ListItemText
                        primary={category}
                        primaryTypographyProps={{
                          sx: {
                            fontFamily: 'Inter',
                            fontWeight: 700,
                            color: '#004F6E',
                            fontSize: '1.1rem',
                          },
                        }}
                      />
                      <ListItemIcon
                        sx={{
                          minWidth: 'unset',
                          marginLeft: 'auto',
                          color: '#004F6E',
                        }}
                      >
                        <ChevronRightIcon />
                      </ListItemIcon>
                    </ListItem>
                  )
                )
              )}
            </List>
          ) : (
            <Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  onClick={() => {
                    if (isAddingAdvancedFilter) {
                      setSelectedCategory(null);
                    } else {
                      setSelectedCategory(null);
                    }
                  }}
                >
                  <ChevronLeftIcon sx={{ color: '#004F6E' }} />
                </IconButton>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: 'Inter',
                    fontWeight: 700,
                    color: '#004F6E',
                    marginLeft: 1,
                  }}
                >
                  {selectedCategory}
                </Typography>
              </Box>

              {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                  <CircularProgress />
                </Box>
              ) : (
                renderCategoryComponent()
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default FilterSidebar;
