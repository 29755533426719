import { Button } from '@mui/material';
import React from 'react';
import { useAuth } from '../../contexts/AuthContext';

const NotFoundPage: React.FC = () => {
  const { setActivePage } = useAuth();

  const handleBackToDashboard = () => {
    setActivePage('dashboard');
  };

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>404 - Page Not Found</h1>
      <p>The page you are looking for does not exist.</p>
      <Button variant="outlined" onClick={handleBackToDashboard}>
        Back To Dashboard
      </Button>
    </div>
  );
};

export default NotFoundPage;
