import React from 'react';
import { Card, CardActionArea, CardContent, Typography, Grid } from '@mui/material';

interface SupporterOptionProps {
  title: string;
  page: string;
  setActivePage: (page: string) => void;
}

const SupporterOption: React.FC<SupporterOptionProps> = ({ title, page, setActivePage }) => {
  const handleNavigate = () => {
    setActivePage(page);
  };

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card 
        variant="outlined" 
        sx={{
          height: '300px', 
          cursor: 'pointer',
          '&:hover': {
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
          },
        }}
        onClick={handleNavigate}
      >
        <CardActionArea style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <CardContent>
            <Typography variant="h5" component="h2">
              {title}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

export default SupporterOption;
