import { apiClient } from './apiClient';
import { toast } from 'react-hot-toast';
import { User, UserDetails } from '../types/auth';

// Utility to generate a unique Device ID
const getDeviceId = (): string => {
  let deviceId = localStorage.getItem('device_id');
  if (!deviceId) {
    deviceId = crypto.randomUUID();
    localStorage.setItem('device_id', deviceId);
  }
  return deviceId;
};

// Fetch correct backend URL based on the environment

const login = async (username: string, password: string): Promise<boolean> => {
  try {
    const response = await apiClient(`/api/auth/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Device-ID': getDeviceId(), // Include Device ID in headers
      },
      body: JSON.stringify({ username, password }),
    });

    if (response.ok) {
      return true; // Login was successful
    } else {
      const data = await response.json();
      console.error('Login failed:', data);
      return false; // Login failed
    }
  } catch (error) {
    console.error('Login error:', error);
    throw error;
  }
};

const register = async (userDetails: UserDetails): Promise<boolean> => {
  try {
    const response = await apiClient(`/api/auth/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Device-ID': getDeviceId(), // Include Device ID in headers
      },
      body: JSON.stringify(userDetails),
    });

    if (response.ok) {
      return true;
    } else {
      const data = await response.json();
      console.error('Registration failed:', data);
      return false;
    }
  } catch (error) {
    console.error('Registration error:', error);
    throw error;
  }
};

const getCurrentUser = async (): Promise<User | null> => {
  try {
    const response = await fetch(`/api/auth/me`, {
      method: 'GET',
      credentials: 'include', // Ensure cookies are sent with the request
      headers: {
        'Device-ID': getDeviceId(), // Include Device ID in headers
      },
    });

    if (response.ok) {
      const data: User = await response.json();
      return data;
    } else {
      const data = await response.json();
      console.error('Failed to fetch current user:', data);
      return null;
    }
  } catch (error) {
    console.error('Error fetching current user:', error);
    throw error;
  }
};

const ssoLogin = async (email: string): Promise<void> => {
  try {
    const response = await apiClient(`/login/sso`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Device-ID': getDeviceId(), // Include Device ID in headers
      },
      body: new URLSearchParams({ email }),
    });

    if (response.redirected) {
      // Redirect the user to the SSO provider's login page
      window.location.href = response.url;
    } else {
      const data = await response.json();
      console.error('SSO login failed:', data);
    }
  } catch (error) {
    console.error('SSO login error:', error);
    throw error;
  }
};

const logout = async (): Promise<void> => {
  try {
    const response = await fetch('/api/auth/logout', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Device-ID': getDeviceId(), // Include Device ID in headers
      },
    });

    if (!response.ok) {
      console.error('Logout failed:', response.statusText);
      toast.error('Failed to logout');
    }
  } catch (error) {
    console.error('Error during logout:', error);
  }
};

export { login, register, getCurrentUser, logout, ssoLogin };