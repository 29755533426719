import React from "react";
import { AppBar, Toolbar, IconButton, Menu, MenuItem, Box } from "@mui/material";
import { Group } from "@mui/icons-material";
import logo from "./assets/images/marketsmart-logo-white.png";
import { useAuth } from "./contexts/AuthContext";
import { Organization } from "./types/organization";
import ProfileDropdown from "./components/organization/ProfileDropdown";

export interface BannerProps {
  selectedOrg: Organization | null;
  setSelectedOrg: React.Dispatch<React.SetStateAction<any>>;
  handleOrgSelect: (org: Organization) => void;
  handleClose: () => void;
  anchorEl?: HTMLElement | null;
  avatarEl?: HTMLElement | null;
  setAnchorEl?: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  setAvatarEl?: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
}

const Banner: React.FC = () => {
  const { user, activePage, setActivePage, userOrgs, organization, setOrganization } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const isOrgMenuOpen = Boolean(anchorEl);

  const handleOrgMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOrgSelect = (org: Organization) => {
    setOrganization(org);
    setAnchorEl(null);
    setActivePage("organization");
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    user && (
      <AppBar position="fixed" style={{ backgroundColor: "#004F6E", padding: "0 10px" }}>
        <Toolbar>
          <Box
            onClick={() => setActivePage("dashboard")}
            style={{ color: "white", textDecoration: "none", cursor: "pointer" }}
          >
            <img src={logo} alt="Marketsmart" style={{ marginRight: 16 }} />
          </Box>
          <div style={{ flexGrow: 1 }} />
          <IconButton
            color="inherit"
            onClick={() => setActivePage("view-supporters")}
            style={{ color: "white", cursor: "pointer", marginRight: "15px" }}
          >
            <Group />
          </IconButton>
          <Menu anchorEl={anchorEl} open={isOrgMenuOpen} onClose={handleClose}>
            {userOrgs.map((org) => (
              <MenuItem key={org.id} onClick={() => handleOrgSelect(org)}>
                {org.name}
              </MenuItem>
            ))}
          </Menu>
          <ProfileDropdown />
        </Toolbar>
      </AppBar>
    )
  );
};

export default Banner;